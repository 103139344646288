import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import logo from '../assets/logoCenterAligned.png';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: theme.spacing(6),
    },
    img: {
        height: 200,
    },
    text: {
        marginTop: theme.spacing(4),
    },
}));

export default function NotFound() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img src={logo} className={classes.img} />
            <Typography
                className={classes.text}
                variant='h4'
            >{`Sorry, we couldn't find that page`}</Typography>
        </div>
    );
}
