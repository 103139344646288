import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { persistor, store } from './configureStore';
import './index.css';
import ErrorFallback from './pages/ErrorFallback';
import './polyfills';
import reportWebVitals from './reportWebVitals';

Sentry.init({
    dsn:
        process.env.REACT_APP_AXLE_ENV === 'production'
            ? 'https://8213dfa63bba440d8aab362be2fd8c74@o495775.ingest.sentry.io/5909721'
            : null,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
                    <App />
                </Sentry.ErrorBoundary>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
