import { Typography, withStyles } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCity, setTier, setUsState, setZipcode } from '../actions';
import { fetchLocationEligibility } from '../api';
import { AxleButton } from '../components';
import AxleInput from '../components/AxleInput';

const styles = theme => ({
    root: {
        height: 'calc(100vh - 70px)',
        boxSizing: 'border-box',
        padding: `${theme.spacing(5)}px 10%`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    content: {
        boxSizing: 'border-box',
        padding: '0 10%',
        border: '1px solid #E8E6E1',
        borderRadius: '10px',
        textAlign: 'left',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 5),
            '& h6': {
                fontSize: '16px',
            },
        },
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(-7),
        marginBottom: theme.spacing(2),
        '& > svg': {
            paddingRight: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                paddingRight: theme.spacing(0),
            },
        },
        '& > h4': {
            margin: 0,
            [theme.breakpoints.down('sm')]: {
                '& b': {
                    fontSize: '24px',
                },
            },
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(-4.75),
        },
    },
    input: {
        padding: theme.spacing(4, 0, 5, 0),
        maxWidth: '500px',
        '& input': {
            textAlign: 'left!important',
        },
    },
    ctaContainer: {
        margin: 0,
    },
    cta: {
        width: '125px',
        height: '50px',
    },
});

class Location extends React.Component {
    state = {
        isLoading: false,
        isSuccessful: false,
        zipCode: '',
        error: '',
    };

    constructor(props) {
        super(props);
    }
    constructParams = () => {
        const params = queryString.parse(this.props.location.search);
        let i = 0;
        let urlStr = '';
        for (var key of Object.keys(params)) {
            if (i === 0) {
                urlStr += `?${key}=${params[key]}`;
            } else {
                urlStr += `&${key}=${params[key]}`;
            }
            i++;
        }

        return urlStr;
    };

    handleGoClick = async event => {
        // Don't remove prevent default. Need this for firefox support
        event.preventDefault();
        this.setState({ isLoading: true });
        const { error, result } = await fetchLocationEligibility({
            zipCode: this.state.zipCode,
            partnerId: this.props.partnerId,
        });

        if (!error) {
            this.props.setZipcode(this.state.zipCode);
            if (result.is_eligible) {
                this.setState({ isLoading: false, isSuccessful: true });
                this.props.setCity(result.city);
                this.props.setUsState(result.us_state);
                this.props.setTier(result.tier);
                setTimeout(() => {
                    this.props.history.push(`/book${this.constructParams()}`);
                }, 1000);
            } else {
                this.setState({ isLoading: false, isSuccessful: false });
                this.props.history.push('/waitlist');
            }
        } else {
            this.setState({ isLoading: false, isSuccessful: false, error });
        }
    };

    handleZipChange = e => {
        const z = e && e.target ? e.target.value : '';
        this.setState({ zipCode: z });
    };

    render() {
        const { classes } = this.props;
        const { isSuccessful, isLoading, error } = this.state;
        return (
            <form className={classes.root} onSubmit={this.handleGoClick}>
                <div className={classes.content}>
                    <div className={classes.title}>
                        <LocationOnIcon color='primary' fontSize='large' />
                        <Typography variant='h4' gutterBottom>
                            <b>What is your zip code?</b>
                        </Typography>
                    </div>
                    <AxleInput
                        label='Zip code'
                        containerClass={classes.input}
                        error={error}
                        inputProps={{
                            maxLength: 10,
                            style: { textAlign: 'center' },
                        }}
                        variant='outlined'
                        placeholder='Zip code'
                        onChange={this.handleZipChange}
                    />
                    <AxleButton
                        containerClass={classes.ctaContainer}
                        className={classes.cta}
                        rounded
                        onClick={this.handleGoClick}
                        size='large'
                        isSuccessful={isSuccessful}
                        isLoading={isLoading}
                        disabled={this.state.zipCode.length < 1}
                        type='submit'
                        trackingEvent={{ name: 'Proceed to Book' }}
                    >
                        Next
                    </AxleButton>
                </div>
            </form>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    // Each of these functions will be added to the props of the component
    // and can be called from the component itself
    setZipcode: val => dispatch(setZipcode(val)),
    setCity: val => dispatch(setCity(val)),
    setUsState: val => dispatch(setUsState(val)),
    setTier: val => dispatch(setTier(val)),
});

const mapStateToProps = state => ({
    partnerId: state.partnerId,
    partnerMeta: state.partnerMeta,
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Location))
);
