import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        maxWidth: '100%',
        boxShadow: `inset 0px 4px 0px ${theme.palette.primary.main}`,
        border: '1px solid #E8E6E1',
        borderRadius: '8px',
        [theme.breakpoints.up('md')]: {
            maxWidth: '50%',
            margin: '0 auto',
            marginTop: '64px',
        },
    },
}));

export default function AxleCardContainer(props) {
    const classes = useStyles();

    return <div className={classes.root}>{props.children}</div>;
}
