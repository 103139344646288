import { InputLabel, makeStyles, TextField } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const getHeight = props => {
    if (props.multiline) {
        return '100px';
    } else {
        return '45px';
    }
};

const getDisabledTextColor = props => {
    return props.fullAlphaDisabledText ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.38)';
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .MuiInputBase-input.Mui-disabled': {
            color: props => getDisabledTextColor(props),
        },
    },
    label: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '10px',
        color: 'black',
        marginBottom: theme.spacing(1.5),
        width: '100%',
        textAlign: 'left',
    },
    input: {
        height: `${props => getHeight(props)}`,
        width: '100%',
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
        },
        '& input': {
            padding: theme.spacing(1.5, 2),
        },
    },
}));

export default function AxleInput({
    label,
    customInput,
    containerClass,
    error,
    customLabel,
    ...rest
}) {
    const classes = useStyles(rest);
    const isError = error ? true : false;
    return (
        <div className={`${classes.root} ${containerClass || ''}`}>
            {customLabel ? (
                customLabel
            ) : (
                <InputLabel className={classes.label} error={isError}>
                    {label}
                </InputLabel>
            )}
            {customInput ? (
                customInput
            ) : (
                <TextField
                    {...rest}
                    className={clsx(classes.input, rest.className)}
                    error={isError}
                    helperText={error}
                />
            )}
        </div>
    );
}
