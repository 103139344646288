import DateFnsUtils from '@date-io/date-fns';
import { InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    label: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '10px',
        color: 'black',
        marginBottom: theme.spacing(1.5),
    },
    dobField: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
        },
        '& input': {
            padding: theme.spacing(1.5, 2),
        },
    },
    hideAdornment: { display: 'none' },
}));

export default function AxleDobField({
    label,
    customInput,
    onDateSelected,
    date,
    required,
    error,
}) {
    const classes = useStyles();

    const handleDateChange = val => {
        onDateSelected(moment(val).format('YYYY-MM-DD'));
    };
    const isError = error || date == 'Invalid date' ? true : false;

    return (
        <div className={classes.root}>
            <InputLabel className={classes.label} error={isError}>
                {label}
            </InputLabel>
            {customInput ? (
                customInput
            ) : (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        required={required ? true : false}
                        className={`${classes.dobField} fs-exclude`}
                        inputVariant='outlined'
                        format='MM/dd/yyyy'
                        placeholder='mm/dd/yyyy'
                        value={date ? moment(date) : null}
                        onChange={handleDateChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputAdornmentProps={{
                            className: classes.hideAdornment,
                        }}
                        error={isError}
                        helperText={isError ? date || error : ''}
                    />
                </MuiPickersUtilsProvider>
            )}
        </div>
    );
}

AxleDobField.propTypes = {
    onDateSelected: PropTypes.func.isRequired,
    date: PropTypes.string,
    required: PropTypes.bool,
    className: PropTypes.string,
};
