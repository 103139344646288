import { Typography, withStyles } from '@material-ui/core';
import Description from '@material-ui/icons/Description';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { accessDocument } from '../api';
import { AxleButton, AxleDobField } from '../components';
import AxleInput from '../components/AxleInput';
const styles = theme => ({
    root: {
        height: 'calc(100vh - 70px)',
        boxSizing: 'border-box',
        padding: `${theme.spacing(5)}px 10%`,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    content: {
        boxSizing: 'border-box',
        padding: '0 10%',
        border: '1px solid #E8E6E1',
        borderRadius: '10px',
        textAlign: 'left',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0, 5),
            '& h6': {
                fontSize: '16px',
            },
        },
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(-7),
        marginBottom: theme.spacing(2),
        '& > svg': {
            paddingRight: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                paddingRight: theme.spacing(0),
            },
        },
        '& > h4': {
            margin: 0,
            [theme.breakpoints.down('sm')]: {
                '& b': {
                    fontSize: '24px',
                },
            },
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(-4.75),
        },
    },
    input: {
        padding: theme.spacing(1, 0, 1, 0),
        maxWidth: '500px',
        '& input': {
            textAlign: 'left!important',
        },
    },
    ctaContainer: {
        margin: theme.spacing(2, 0, 0, 0),
    },
    cta: {
        width: '125px',
        height: '50px',
    },
});

const Document = props => {
    const { classes, match } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [dob, setDob] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastname] = useState(null);

    const isInvalidDob = !dob || dob == 'Invalid date' ? true : false;
    const isInvalidFirstName = !firstName ? true : false;
    const isInvalidLastName = !lastName ? true : false;

    const handleGoClick = async event => {
        // Don't remove prevent default. Need this for firefox support
        event.preventDefault();
        setIsLoading(true);
        const { result } = await accessDocument({
            docId: match.params.docId,
            dob: dob,
            firstName: firstName,
            lastName: lastName,
        });
        setIsLoading(false);

        if (result) {
            setIsSuccess(true);
            setTimeout(window.open(result.link, '_self'), 1000);
        } else {
            setIsSuccess(false);
        }
    };

    return (
        <form className={classes.root} onSubmit={handleGoClick}>
            <div className={classes.content}>
                <div className={classes.title}>
                    <Description color='primary' fontSize='large' />
                    <Typography variant='h4' gutterBottom>
                        <b>Complete fields below to view document</b>
                    </Typography>
                </div>
                <div className={classes.input}>
                    <AxleInput
                        label='First name'
                        required
                        containerClass={classes.input}
                        variant='outlined'
                        placeholder='First Name'
                        onChange={e => setFirstName(e.target.value)}
                    />
                </div>
                <div className={classes.input}>
                    <AxleInput
                        label='Last name'
                        required
                        containerClass={classes.input}
                        variant='outlined'
                        placeholder='Last Name'
                        onChange={e => setLastname(e.target.value)}
                    />
                </div>
                <div className={classes.input}>
                    <AxleDobField
                        label='Date of Birth'
                        required
                        containerClass={classes.input}
                        date={dob}
                        onDateSelected={setDob}
                    />
                </div>
                <AxleButton
                    containerClass={classes.ctaContainer}
                    className={classes.cta}
                    rounded
                    onClick={handleGoClick}
                    size='large'
                    isSuccessful={isSuccess}
                    isLoading={isLoading}
                    disabled={isInvalidDob || isInvalidFirstName || isInvalidLastName}
                    type='submit'
                    trackingEvent={{ name: 'Proceed to Document' }}
                >
                    Enter
                </AxleButton>
            </div>
        </form>
    );
};

const mapStateToProps = state => ({
    partnerId: state.partnerId,
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Document)));
