import CommentRoundedIcon from '@material-ui/icons/CommentRounded';
import React from 'react';
import AxleAccordionContainer from '../AxleAccordionContainer';
import AxleEditor from '../AxleEditor';

export default function NoteContainer({ onChange, value }) {
    return (
        <AxleAccordionContainer
            title='Notes for provider'
            icon={<CommentRoundedIcon fontSize='small' color='primary' />}
        >
            <AxleEditor isRichText onChange={onChange} value={value} />
        </AxleAccordionContainer>
    );
}
