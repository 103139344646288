import { makeStyles } from '@material-ui/core';
import ScheduleIcon from '@material-ui/icons/Schedule';
import React from 'react';
import AxleAccordionContainer from '../../components/AxleAccordionContainer';
import AxleTimesgrid from '../../components/AxleTimesgrid';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            '& .MuiAccordionDetails-root': {
                padding: 0,

                '& > .MuiBox-root': {
                    padding: 0,
                    '& .subTitle': {
                        paddingBottom: 0,
                        '& > p': {
                            paddingLeft: theme.spacing(6),
                            paddingRight: theme.spacing(2),
                        },
                    },
                },
            },
        },
    },
    scheduleButton: {
        width: '400px',
        [theme.breakpoints.down('md')]: {
            width: '300px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '250px',
        },
    },
    scheduleButtonContainer: {
        marginTop: theme.spacing(2),
    },
}));

export default function ScheduleContainerScheduling({
    address,
    isLoadingTimesgrid,
    isServiceSelected,
    visitTime,
    onDatetimeSelected,
    availability,
    tier,
    helpText,
}) {
    const classes = useStyles();

    var noAvailabilityText = '';
    var noAvailabilityAction = null;

    let scheduleSubTitle;
    if (tier === 1) {
        scheduleSubTitle = 'Select the time and date for your visit.';
    } else if (tier === 2) {
        scheduleSubTitle = `Select the time and date for your visit. The time you select is not guaranteed. A member of our team will reach out to confirm your visit time.`;
    } else {
        scheduleSubTitle = `Select your date and time preferences. The time and date you select are not guaranteed. A member of our team will reach out to confirm your visit time.`;
    }

    return (
        <AxleAccordionContainer
            title='When?'
            subTitle={scheduleSubTitle}
            isLoading={isLoadingTimesgrid}
            icon={<ScheduleIcon fontSize='small' color='primary' />}
            className={classes.root}
        >
            <AxleTimesgrid
                isLoading={isLoadingTimesgrid}
                disabled={!address.zipCode || !isServiceSelected}
                availability={availability}
                onDatetimeSelected={onDatetimeSelected}
                visitTime={visitTime}
                noAvailabilityText={noAvailabilityText}
                noAvailabilityAction={noAvailabilityAction}
                helpText={helpText}
                isAvailabilityTimeWindow={tier === 3}
            />
        </AxleAccordionContainer>
    );
}
