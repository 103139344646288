import { Avatar, Button, Divider, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import poweredByAxle from '../assets/poweredByAxle.png';

const useStyles = makeStyles(theme => ({
    container: {
        borderRadius: '66px 66px 0 0',
        width: '75%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        zIndex: 9999,
        position: 'absolute',
        bottom: 0,
    },
    professional: {
        display: 'flex',
        flexDirection: 'row',
        width: '70%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        overflow: 'visible',
        whiteSpace: 'nowrap',
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'center',
        },
    },
    visit: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    etaContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexGrow: 1,
        width: '100%',
    },
    eta: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
    contact: {
        marginBottom: theme.spacing(3),
    },
    divider: {
        width: '100%',
    },
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    poweredBy: {
        backgroundColor: '#F0F0F0',
        width: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    name: {
        marginLeft: theme.spacing(1),
    },
    // TODO: Use built-in typography variants once we change the font across the app
    montserratBold: {
        fontSize: 14,
        textTransform: 'uppercase',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 900,
        letterSpacing: 2,
    },
    montserrat: {
        fontSize: 14,
        textTransform: 'uppercase',
        fontFamily: 'Montserrat, sans-serif',
        letterSpacing: 2,
    },
}));

export default function ProfessionalInfoCard(props) {
    const classes = useStyles();
    const date = moment(props.date).format('ddd, MMMM Do');
    return (
        <Paper className={classes.container}>
            <div className={classes.professional}>
                {/* For some reason alt text isn't working, so using a ternary*/}
                {props.professionalImgSrc ? (
                    <Avatar
                        className={classes.avatar}
                        alt={props.name}
                        src={props.professionalImgSrc}
                    />
                ) : (
                    <Avatar className={classes.avatar}>{props.name[0]}</Avatar>
                )}
                <Typography className={`${classes.name} ${classes.montserratBold}`}>
                    {props.name}
                </Typography>
            </div>
            <Divider className={classes.divider} variant='middle' />
            <div className={classes.visit}>
                {date && date !== 'Invalid date' && (
                    <Typography className={classes.montserratBold}>{date}</Typography>
                )}
                <div className={classes.etaContainer}>
                    <Typography className={classes.montserratBold}>Arrival</Typography>
                    {props.eta && props.eta !== 'Invalid date' && (
                        <Typography className={classes.eta}>{props.eta}</Typography>
                    )}
                </div>
            </div>
            {props.phone && (
                <div className={classes.contact}>
                    <Button
                        variant='text'
                        color='primary'
                        size='small'
                        startIcon={<PhoneIcon style={{ fill: 'black' }} />}
                        onClick={() => window.open(`tel:${props.phone}`)}
                    >
                        Call clinician
                    </Button>
                    <Button
                        variant='text'
                        color='primary'
                        size='small'
                        startIcon={<PhoneIphoneIcon style={{ fill: 'black' }} />}
                        onClick={() => window.open(`sms:${props.phone}`)}
                    >
                        Text clinician
                    </Button>
                </div>
            )}
            {props.showPoweredBy && (
                <div className={classes.poweredBy}>
                    <img src={poweredByAxle} style={{ height: '24px' }} />
                </div>
            )}
        </Paper>
    );
}

ProfessionalInfoCard.propTypes = {
    name: PropTypes.string.isRequired,
    professionalImgSrc: PropTypes.string,
    phone: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    eta: PropTypes.string.isRequired,
    showPoweredBy: PropTypes.bool,
};
