import { Button, Divider, Grid, makeStyles, MenuItem } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
import { get } from 'lodash';
import React from 'react';
import { BaseColors } from '../../constants';
import AxleAccordionContainer from '../AxleAccordionContainer';
import AxleDobField from '../AxleDobField';
import AxleInput from '../AxleInput';
import AxlePhoneMask from '../AxlePhoneMask';
import AxleSelect from '../AxleSelect';

const useStyles = makeStyles(theme => ({
    patientInfosContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
        },
    },
    hyperlink: {
        color: BaseColors.mainColor,
        textDecoration: 'none',
    },
    addButton: {
        color: 'black',
        paddingLeft: 0,
        paddingTop: theme.spacing(2),
    },
    addIcon: {
        width: 24,
        height: 24,
        borderRadius: '50%',
        border: '1px solid black',
        color: 'black',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(1 / 4),
        },
    },
}));

export default function PersonalInfoContainer({
    patients,
    areServicesPreset,
    handleAddPatientClick,
    handlePatientInfoFieldChange,
    handleRemovePatientClick,
    errors,
    supportedLanguages,
    sectionRef,
}) {
    const classes = useStyles();

    return (
        <AxleAccordionContainer
            title='Who?'
            icon={<FaceIcon fontSize='small' color='primary' />}
            subTitle='Please fill out the following patient information.'
            containerRef={sectionRef}
        >
            <div className={classes.patientInfosContainer}>
                {patients.map((patient, i) => {
                    return (
                        <Grid key={i} container spacing={2} style={{ paddingTop: '16px' }}>
                            <Grid item md={6}>
                                <AxleInput
                                    className={`${classes.patientTextfield} fs-exclude`}
                                    value={patient.firstName}
                                    variant='outlined'
                                    placeholder='First Name'
                                    label='First Name'
                                    name='firstname'
                                    onChange={e =>
                                        handlePatientInfoFieldChange({
                                            value: e.target ? e.target.value : '',
                                            id: i,
                                            field: 'firstName',
                                        })
                                    }
                                    required
                                    error={get(errors, `[${patient.id}][firstName]`)}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <AxleInput
                                    className={`${classes.patientTextfield} fs-exclude`}
                                    value={patient.lastName}
                                    variant='outlined'
                                    placeholder='Last Name'
                                    label={'Last Name'}
                                    name='lastname'
                                    onChange={e =>
                                        handlePatientInfoFieldChange({
                                            value: e.target ? e.target.value : '',
                                            id: i,
                                            field: 'lastName',
                                        })
                                    }
                                    required
                                    error={get(errors, `[${patient.id}][lastName]`)}
                                />
                            </Grid>

                            <Grid item md={6}>
                                <AxleInput
                                    className={`${classes.patientTextfield} fs-exclude`}
                                    value={patient.email}
                                    variant='outlined'
                                    placeholder='youremail@me.com'
                                    label={'Email'}
                                    name='email'
                                    onChange={e =>
                                        handlePatientInfoFieldChange({
                                            value: e.target ? e.target.value : '',
                                            id: i,
                                            field: 'email',
                                        })
                                    }
                                    error={get(errors, `[${patient.id}][email]`)}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <AxleInput
                                    className={`${classes.patientTextfield} fs-exclude`}
                                    value={patient.phone}
                                    variant='outlined'
                                    label={'Phone'}
                                    name='phone'
                                    onChange={e =>
                                        handlePatientInfoFieldChange({
                                            value: e.target ? e.target.value : '',
                                            id: i,
                                            field: 'phone',
                                        })
                                    }
                                    required
                                    InputProps={{
                                        inputComponent: AxlePhoneMask,
                                    }}
                                    error={get(errors, `[${patient.id}][phone]`)}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <AxleDobField
                                    label='Date of Birth'
                                    required
                                    date={patient.dob}
                                    onDateSelected={dob =>
                                        handlePatientInfoFieldChange({
                                            value: dob,
                                            id: i,
                                            field: 'dob',
                                        })
                                    }
                                    error={get(errors, `[${patient.id}][dob]`)}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <AxleSelect
                                    label='Biological Sex'
                                    name={'sex'}
                                    value={patient.sex}
                                    required
                                    onChange={e =>
                                        handlePatientInfoFieldChange({
                                            value: e.target ? e.target.value : '',
                                            id: i,
                                            field: 'sex',
                                        })
                                    }
                                    error={get(errors, `[${patient.id}][sex]`)}
                                >
                                    <MenuItem value={'M'}>Male</MenuItem>
                                    <MenuItem value={'F'}>Female</MenuItem>
                                </AxleSelect>
                            </Grid>
                            <Grid item md={6}>
                                <AxleSelect
                                    label='Preferred language'
                                    value={
                                        patient.preferredLanguage ? patient.preferredLanguage : 'en'
                                    }
                                    required
                                    onChange={e =>
                                        handlePatientInfoFieldChange({
                                            value: e.target ? e.target.value : '',
                                            id: i,
                                            field: 'preferredLanguage',
                                        })
                                    }
                                >
                                    {supportedLanguages.map(lang => (
                                        <MenuItem key={lang.abbrev} value={lang.abbrev}>
                                            {lang.verbose}
                                        </MenuItem>
                                    ))}
                                </AxleSelect>
                            </Grid>
                            {i !== 0 ? (
                                <Grid item md={12}>
                                    <Button
                                        variant='outlined'
                                        color='secondary'
                                        onClick={handleRemovePatientClick}
                                    >
                                        Remove this patient
                                    </Button>
                                </Grid>
                            ) : null}
                            {i === 0 && patients.length > 1 ? (
                                <Divider className={classes.divider} />
                            ) : null}
                        </Grid>
                    );
                })}
                {!areServicesPreset ? (
                    <Grid item md={12} style={{ textAlign: 'left' }}>
                        <Button
                            variant='text'
                            color='primary'
                            onClick={handleAddPatientClick}
                            className={classes.addButton}
                        >
                            <div className={classes.addIcon}>+</div> Add another patient to this
                            visit
                        </Button>
                    </Grid>
                ) : null}
            </div>
        </AxleAccordionContainer>
    );
}
