import {
    Avatar,
    Backdrop,
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Snackbar,
    Typography,
    withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import RoomIcon from '@material-ui/icons/Room';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MuiAlert from '@material-ui/lab/Alert';
import GoogleMapReact from 'google-map-react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setPartnerMeta } from '../actions';
import { cancelVisit, fetchVisitDetails, getAvailability, rescheduleVisit } from '../api';
import { AxleButton, AxleTimesgrid, ProfessionalInfoCard } from '../components';
import AxleInput from '../components/AxleInput';
import AxleSelect from './../components/AxleSelect';
import { cancellationOptions } from './../constants';
import { keysSnakeToCamel } from './../utils';

const styles = theme => ({
    img: {
        width: '160px',
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    infoColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    infoValueRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    infoIconContainer: {
        marginRight: '6px',
        marginTop: '4px',
    },
    infoTitle: { marginLeft: '24px' },
    confirmationInfoContainer: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    infoTypo: {
        textAlign: 'left',
    },
    enRouteMapWrapper: {
        width: '100%',
        height: 'calc(80vh - 64px)',
    },
    confirmationMapWrapper: {
        width: '100%',
        height: 'calc(40vh - 64px)',
        marginBottom: theme.spacing(2),
    },
    ctaContainer: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    editVisitCta: {
        marginTop: theme.spacing(2),
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
    enRouteContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    timeContainer: {
        height: 'calc(100% - 350px)',
        paddingBottom: theme.spacing(5),
        overflow: 'auto',
    },
});

const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
const HomeMarkerIcon = () => <RoomIcon style={{ transform: 'translate(-50%, -50%)' }} />;

class Confirmation extends React.Component {
    state = {
        availability: [],
        address: '',
        datetime: '',
        // patient address coords
        addressCoordinates: {},
        eta: '',
        // Qhp coords
        qhpCoordinates: {},
        qhpName: '',
        qhpImgSrc: '',
        qhpPhone: '',
        statusTitle: '',
        statusDescription: '',
        isCompleted: false,
        logoUrl: '',
        presentBackdrop: true,
        showPoweredBy: false,
        showTimesgrid: false,
        // For fetching availability for rescheduling
        zipCode: '',
        isSelfPay: false,
        services: [],
        partnerId: '',
        snackbar: {},
        showCancelDialog: false,
        cancellationReason: '',
        reasonWhy: '',
        showAlertModification: false,
    };

    async componentDidMount() {
        await this.getVisitDetails();
    }

    async getVisitDetails() {
        const { result } = await fetchVisitDetails(this.props.match.params.visitId);
        if (result) {
            this.props.setPartnerMeta({
                ...this.props.partnerMeta,
                logo_url: result.logo_url,
            });
            this.setState({
                address: result.address,
                addressCoordinates: result.address_coords,
                datetime: result.visit_datetime,
                qhpCoordinates: result.qhp_coordinates,
                qhpName: result.qhp_name,
                qhpImgSrc: result.qhp_img_src,
                qhpPhone: result.qhp_phone,
                statusTitle: result.status_title,
                statusDescription: result.status_description,
                isCompleted: result.is_completed,
                showPoweredBy: result.logo_url ? true : false,
                zipCode: result.zip_code,
                isSelfPay: result.is_self_pay,
                services: result.services,
                presentBackdrop: false,
                partnerId: result.partner_id,
                allowsReschedule: result.allows_reschedule,
                allowsCancel: result.allows_cancel,
                alertLateVisitModification: result.alert_late_visit_modification,
            });
        } else {
            this.setState({ presentBackdrop: false });
        }
    }

    // --------------------------------------------------------------------------------
    // Mapping Methods
    // --------------------------------------------------------------------------------

    coordsValid = ({ coords }) => {
        if (coords) {
            return isNaN(coords.lat) || isNaN(coords.lng) ? false : true;
        }
        return false;
    };

    renderMarker = ({ lat, lng, qhpImgSrc }) => {
        if (this.coordsValid({ coords: { lat: lat, lng: lng } })) {
            return (
                <Avatar
                    src={qhpImgSrc}
                    lat={lat}
                    lng={lng}
                    style={{
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            );
        } else {
            return null;
        }
    };

    renderHomeMarker = ({ lat, lng }) => {
        if (this.coordsValid({ coords: { lat: lat, lng: lng } })) {
            return <HomeMarkerIcon lat={lat} lng={lng} />;
        } else {
            return null;
        }
    };

    getCoordinates = () => {
        // Check for both address and qhp coords b/c this determines whether we
        // display the map at all
        const { addressCoordinates, qhpCoordinates } = this.state;
        return addressCoordinates &&
            addressCoordinates.lat &&
            addressCoordinates.lng &&
            qhpCoordinates &&
            qhpCoordinates.lat &&
            qhpCoordinates.lng
            ? {
                  lat: parseFloat(addressCoordinates.lat),
                  lng: parseFloat(addressCoordinates.lng),
              }
            : null;
    };

    isEnRoute = () => {
        return this.getCoordinates() ? true : false;
    };

    handleEditVisit = async () => {
        this.setState({ presentBackdrop: true }, async () => {
            const { services, zipCode, partnerId, alertLateVisitModification } = this.state;
            if (alertLateVisitModification) {
                this.setState({
                    showAlertModification: true,
                    presentBackdrop: false,
                });
            } else {
                // TODO: We should show the help text field returned by the
                //  availability endpoint in the UX somewhere.
                const { result, error } = await getAvailability({
                    services,
                    zipCode,
                    partnerId,
                    visitId: this.props.match.params.visitId,
                });
                if (result) {
                    this.setState({
                        availability: result,
                        showTimesgrid: true,
                        presentBackdrop: false,
                    });
                } else {
                    this.setState({
                        presentBackdrop: false,
                        snackbar: {
                            show: true,
                            isError: true,
                            message: error,
                            severity: 'error',
                        },
                    });
                }
            }
        });
    };

    handleCancelVisit = async () => {
        this.setState({ showCancelDialog: false, presentBackdrop: true }, async () => {
            const { error, result } = await cancelVisit(this.props.match.params.visitId);
            if (!error) {
                const message = result['did_refund']
                    ? 'Visit canceled! Your refund will be processed onto your original payment method in 5-10 business days.'
                    : 'Visit canceled!';
                await this.getVisitDetails();
                this.setState({
                    presentBackdrop: false,
                    snackbar: {
                        show: true,
                        message,
                        severity: 'success',
                    },
                });
            } else {
                this.setState({
                    presentBackdrop: false,
                    snackbar: {
                        show: true,
                        isError: true,
                        message: error,
                        severity: 'error',
                    },
                });
            }
        });
    };

    handleLateReschedule = async () => {
        this.setState({ alertLateVisitModification: false, presentBackdrop: true }, async () => {
            const { error } = await cancelVisit(this.props.match.params.visitId);
            if (!error) {
                this.props.history.push(`/location?partner_id=${this.state.partnerId}`);
            } else {
                this.setState({
                    presentBackdrop: false,
                    snackbar: {
                        show: true,
                        isError: true,
                        message: error,
                        severity: 'error',
                    },
                });
            }
        });
    };

    handleDatetimeSelected = vd => {
        this.setState({ presentBackdrop: true }, async () => {
            const { error } = await rescheduleVisit({
                visitId: this.props.match.params.visitId,
                visitDatetime: vd,
            });
            if (!error) {
                await this.getVisitDetails();
                this.setState({
                    showTimesgrid: false,
                    presentBackdrop: false,
                    snackbar: {
                        show: true,
                        message: 'Visit rescheduled!',
                        severity: 'success',
                    },
                });
            } else {
                this.setState({
                    presentBackdrop: false,
                    snackbar: {
                        show: true,
                        isError: true,
                        message: error,
                        severity: 'error',
                    },
                });
            }
        });
    };

    handleApiLoaded = (map, maps) => {
        const { addressCoordinates, qhpCoordinates } = this.state;
        if (isEmpty(addressCoordinates) || isEmpty(qhpCoordinates)) {
            return;
        }
        var directionsService = new maps.DirectionsService();
        var directionsRenderer = new maps.DirectionsRenderer();
        directionsRenderer.setMap(map);
        directionsRenderer.setOptions({
            suppressMarkers: true,
            // Prevents re-centering after calculating directions
            preserveViewport: true,
        });
        if (directionsService && directionsRenderer) {
            var request = {
                origin: `${addressCoordinates.lat},${addressCoordinates.lng}`,
                destination: `${qhpCoordinates.lat},${qhpCoordinates.lng}`,
                travelMode: 'DRIVING',
                drivingOptions: {
                    departureTime: new Date(),
                    trafficModel: 'bestguess',
                },
            };
            directionsService.route(request, (response, status) => {
                if (status == 'OK') {
                    if (response.routes && response.routes.length) {
                        const dir = response.routes[0];
                        if (dir.legs && dir.legs.length) {
                            const leg = dir.legs[0];
                            if (leg.duration_in_traffic) {
                                this.setState({
                                    eta: moment().add(leg.duration_in_traffic.value, 'seconds'),
                                });
                            } else {
                                this.setState({
                                    eta: moment().add(leg.duration.value, 'seconds'),
                                });
                            }
                        }
                    }
                    directionsRenderer.setDirections(response);
                }
            });
        }
    };

    getEtaDisplayVal = () => {
        const { eta } = this.state;
        return moment(eta).format('LT');
    };

    renderMap = ({ centerCoords, markers }) => {
        if (!this.coordsValid({ coords: centerCoords })) {
            return null;
        }
        const { classes } = this.props;
        return (
            <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                bootstrapURLKeys={{
                    key: GOOGLE_MAPS_KEY,
                }}
                defaultCenter={centerCoords}
                defaultZoom={12}
                className={classes.map}
                options={{
                    // Removes full screen icon in upper right
                    fullscreenControl: false,
                    // Removes +/- zoom control on bottom right
                    zoomControl: false,
                    maxZoom: 12,
                }}
            >
                {markers}
            </GoogleMapReact>
        );
    };

    renderTimesgrid = () => {
        const { classes } = this.props;
        const { showTimesgrid, availability } = this.state;
        return showTimesgrid ? (
            <Dialog onClose={this.handleTimesgridClose} open={showTimesgrid} fullScreen>
                <div>
                    <DialogTitle>Select a time</DialogTitle>
                    <IconButton onClick={() => this.setState({ showTimesgrid: false })}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <AxleTimesgrid
                    onDatetimeSelected={this.handleDatetimeSelected}
                    availability={availability}
                    timeContainerClass={classes.timeContainer}
                    // We don't show a reschedule button when on tier 3, so we will never display the times as windows.
                    isAvailabilityTimeWindow={false}
                />
            </Dialog>
        ) : null;
    };

    renderAlertModification = () => {
        const { showAlertModification } = this.state;
        return showAlertModification ? (
            <Dialog
                onClose={() => this.setState({ showAlertModification: false })}
                open={showAlertModification}
            >
                <DialogTitle>Late Reschedule Policy</DialogTitle>
                <DialogContent>
                    Your visit is coming up soon. By clicking the reschedule button below, your
                    visit will be canceled and you will be redirected to book a new visit.{' '}
                    <b>
                        No credit will apply from your previous booking and it will not be refunded.
                    </b>{' '}
                    We work hard to make sure the best healthcare professionals are available when
                    you need them and we need to compensate them for their time. We hope you
                    understand.
                </DialogContent>
                <DialogActions>
                    <AxleButton
                        rounded
                        onClick={() => this.setState({ showAlertModification: false })}
                        color='secondary'
                        autoFocus
                        size='large'
                        variant='outlined'
                    >
                        Keep Current Time
                    </AxleButton>
                    <AxleButton
                        rounded
                        onClick={this.handleLateReschedule}
                        color='primary'
                        size='large'
                    >
                        Reschedule
                    </AxleButton>
                </DialogActions>
            </Dialog>
        ) : null;
    };

    renderDidNotShowReschedule = () => {
        const { allowsReschedule } = this.state;
        const { classes } = this.props;

        const params = keysSnakeToCamel(queryString.parse(this.props.location.search));
        const showRescheduleAsSchedule = params['showSchedule'] === 'true';

        return allowsReschedule ? (
            <div>
                <AxleButton
                    rounded
                    className={classes.editVisitCta}
                    size='large'
                    variant='outlined'
                    onClick={this.handleEditVisit}
                >
                    {!showRescheduleAsSchedule ? 'Reschedule' : 'Schedule'}
                </AxleButton>
                {this.renderAlertModification()}
            </div>
        ) : null;
    };

    renderVisitConfirmation = () => {
        const { classes } = this.props;
        const {
            address,
            datetime,
            statusTitle,
            isCompleted,
            statusDescription,
            addressCoordinates,
            allowsReschedule,
            allowsCancel,
            showCancelDialog,
        } = this.state;
        return (
            <div>
                <div className={classes.confirmationMapWrapper}>
                    {this.renderMap({
                        centerCoords: {
                            lat: parseFloat(addressCoordinates.lat),
                            lng: parseFloat(addressCoordinates.lng),
                        },
                        markers: [
                            this.renderHomeMarker({
                                lat: parseFloat(addressCoordinates.lat),
                                lng: parseFloat(addressCoordinates.lng),
                            }),
                        ],
                    })}
                </div>
                <Typography variant='h5' gutterBottom style={{ padding: 4 }}>
                    <b>{statusTitle}</b>
                </Typography>
                {this.renderTimesgrid()}
                {this.renderAlertModification()}
                {!isCompleted ? (
                    <div className={classes.confirmationInfoContainer}>
                        <Typography variant='subtitle1' gutterBottom>
                            {statusDescription}
                        </Typography>
                        <div className={classes.flexRow}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <div className={classes.infoColumn}>
                                    <Typography variant='subtitle1' className={classes.infoTitle}>
                                        <b>When</b>
                                    </Typography>
                                    <div className={classes.infoValueRow}>
                                        <div className={classes.infoIconContainer}>
                                            <ScheduleIcon fontSize='small' color='primary' />
                                        </div>
                                        <Typography variant='subtitle1'>{`${datetime}`}</Typography>
                                    </div>
                                </div>

                                <div className={classes.flexRow}>
                                    <div className={classes.infoColumn}>
                                        <Typography
                                            variant='subtitle1'
                                            className={classes.infoTitle}
                                        >
                                            <b>Where</b>
                                        </Typography>
                                        <div className={classes.infoValueRow}>
                                            <div className={classes.infoIconContainer}>
                                                <LocationOnIcon fontSize='small' color='primary' />
                                            </div>
                                            <Typography
                                                variant='subtitle1'
                                                style={{ textAlign: 'left' }}
                                            >
                                                {` ${address}`}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.ctaContainer}>
                            {allowsReschedule && (
                                <AxleButton
                                    rounded
                                    className={classes.editVisitCta}
                                    size='large'
                                    variant='outlined'
                                    onClick={this.handleEditVisit}
                                >
                                    Reschedule
                                </AxleButton>
                            )}
                            {allowsCancel && (
                                <AxleButton
                                    rounded
                                    color='secondary'
                                    className={classes.editVisitCta}
                                    size='large'
                                    variant='outlined'
                                    onClick={() => {
                                        this.setState({ showCancelDialog: true });
                                    }}
                                >
                                    Cancel
                                </AxleButton>
                            )}
                        </div>
                        <Dialog open={showCancelDialog} onClose={this.handleCloseCancelDialog}>
                            <DialogTitle>Why are you cancelling your upcoming visit?</DialogTitle>
                            <DialogContent style={{ overflow: 'hidden' }}>
                                <AxleSelect
                                    label='Cancellation Reason *'
                                    value={this.state.cancellationReason}
                                    required
                                    onChange={e =>
                                        this.setState({
                                            cancellationReason: e.target?.value,
                                        })
                                    }
                                >
                                    {cancellationOptions.map(value => (
                                        <MenuItem value={value} key={value}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </AxleSelect>
                                {this.state.cancellationReason == 'Other' && (
                                    <AxleInput
                                        placeholder='Why are you canceling?'
                                        variant='outlined'
                                        value={this.state.reasonWhy}
                                        onChange={e =>
                                            this.setState({
                                                reasonWhy: e.target.value,
                                            })
                                        }
                                    />
                                )}
                                <Box mt={2}>
                                    <Typography>
                                        Your visit is not cancelled until you select a cancellation
                                        reason.
                                    </Typography>
                                </Box>
                            </DialogContent>

                            <DialogActions>
                                <AxleButton
                                    rounded
                                    onClick={this.handleCancelVisit}
                                    color='secondary'
                                    autoFocus
                                    size='large'
                                    variant='outlined'
                                    disableElevation={true}
                                    disabled={
                                        !this.state.cancellationReason ||
                                        (this.state.cancellationReason == 'Other' &&
                                            !this.state.reasonWhy)
                                            ? true
                                            : false
                                    }
                                    trackingEvent={{
                                        cancel:
                                            this.state.cancellationReason == 'Other'
                                                ? `Other - ${this.state.reasonWhy}`
                                                : this.state.cancellationReason,
                                    }}
                                >
                                    Cancel Visit
                                </AxleButton>
                                <AxleButton
                                    rounded
                                    onClick={this.handleCloseCancelDialog}
                                    color='primary'
                                    size='large'
                                >
                                    {"Don't Cancel"}
                                </AxleButton>
                            </DialogActions>
                        </Dialog>
                    </div>
                ) : (
                    this.renderDidNotShowReschedule()
                )}
            </div>
        );
    };

    renderEnRoute = () => {
        const { classes } = this.props;
        const {
            qhpCoordinates,
            addressCoordinates,
            showPoweredBy,
            datetime,
            qhpName,
            qhpImgSrc,
            qhpPhone,
        } = this.state;

        return (
            <div className={classes.enRouteContainer}>
                <div className={`${classes.enRouteMapWrapper} fs-exclude`}>
                    {this.renderMap({
                        centerCoords: {
                            lat: this.getCoordinates().lat,
                            lng: this.getCoordinates().lng,
                        },
                        markers: [
                            this.renderMarker({
                                lat: parseFloat(qhpCoordinates.lat),
                                lng: parseFloat(qhpCoordinates.lng),
                                qhpImgSrc,
                            }),
                            this.renderHomeMarker({
                                lat: parseFloat(addressCoordinates.lat),
                                lng: parseFloat(addressCoordinates.lng),
                            }),
                        ],
                    })}
                </div>

                <ProfessionalInfoCard
                    name={qhpName}
                    professionalImgSrc={qhpImgSrc}
                    phone={qhpPhone}
                    date={datetime}
                    eta={this.getEtaDisplayVal()}
                    showPoweredBy={showPoweredBy}
                />
            </div>
        );
    };

    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {},
        });
    };

    handleCloseCancelDialog = () => {
        this.setState({
            showCancelDialog: false,
        });
    };

    render() {
        const { classes } = this.props;
        const { presentBackdrop, snackbar } = this.state;
        return (
            <div>
                <Backdrop
                    className={classes.backdrop}
                    open={presentBackdrop}
                    onClick={() => this.setState({ presentBackdrop: false })}
                >
                    <CircularProgress color='inherit' />
                </Backdrop>
                {/* Backdrop is shown when loading, so if we're not loading, show the screen we should be showing. */}
                {!presentBackdrop &&
                    (this.isEnRoute() ? this.renderEnRoute() : this.renderVisitConfirmation())}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={snackbar.show}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    style={{ position: 'absolute', zIndex: '10000' }}
                >
                    <MuiAlert onClose={this.handleCloseSnackbar} severity={snackbar.severity}>
                        {snackbar.message}
                    </MuiAlert>
                </Snackbar>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    setPartnerMeta: val => dispatch(setPartnerMeta(val)),
});

const mapStateToProps = state => ({
    partnerMeta: state.partnerMeta,
});

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Confirmation))
);
