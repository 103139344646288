import { Grid } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import React from 'react';
import AxleAccordionContainer from '../AxleAccordionContainer';
import AxleInput from '../AxleInput';

export default function AddressContainer({
    address,
    handleAddressChange,
    getStateSelectValue,
    disableCityStateZip,
    onDisabledFieldClicked,
    sectionRef,
}) {
    const handleDisabledFieldClick = () => {
        if (disableCityStateZip && onDisabledFieldClicked) {
            onDisabledFieldClicked();
        }
    };
    return (
        <AxleAccordionContainer
            title='Where?'
            icon={<LocationOnIcon fontSize='small' color='primary' />}
            subTitle='Where will the health professional be headed?'
            containerRef={sectionRef}
        >
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <AxleInput
                        className={`fs-exclude`}
                        name='address1'
                        value={address.address1}
                        variant='outlined'
                        placeholder='123 Main St.'
                        label={'Address 1'}
                        onChange={e =>
                            handleAddressChange({
                                value: e.target ? e.target.value : '',
                                field: 'address1',
                            })
                        }
                        required
                    />
                </Grid>
                <Grid item md={12}>
                    <AxleInput
                        className={`fs-exclude`}
                        value={address.address2}
                        variant='outlined'
                        name='address2'
                        placeholder='Apt 2'
                        label='Address 2'
                        onChange={e =>
                            handleAddressChange({
                                value: e.target ? e.target.value : '',
                                field: 'address2',
                            })
                        }
                    />
                </Grid>

                <Grid item md={5} xs={12}>
                    <AxleInput
                        fullAlphaDisabledText
                        value={address.city}
                        variant='outlined'
                        name='city'
                        placeholder='Santa Monica'
                        label={'City'}
                        disabled={disableCityStateZip}
                        onClick={handleDisabledFieldClick}
                        onChange={e =>
                            handleAddressChange({
                                value: e.target ? e.target.value : '',
                                field: 'city',
                            })
                        }
                        required
                    />
                </Grid>
                <Grid item md={2} xs={12}>
                    <AxleInput
                        label='State*'
                        variant='outlined'
                        value={getStateSelectValue(address.state)}
                        required
                        disabled={disableCityStateZip}
                        onClick={handleDisabledFieldClick}
                        onChange={e =>
                            handleAddressChange({
                                value: e.target ? e.target.value : '',
                                field: 'state',
                            })
                        }
                    />
                </Grid>
                <Grid item md={5} xs={12}>
                    <AxleInput
                        fullAlphaDisabledText
                        value={address.zipCode}
                        variant='outlined'
                        placeholder='90402'
                        name='zipcode'
                        disabled={disableCityStateZip}
                        label={'Zip Code'}
                        inputProps={{ maxLength: 5 }}
                        onClick={handleDisabledFieldClick}
                        onChange={e =>
                            handleAddressChange({
                                value: e.target ? e.target.value : '',
                                field: 'zipCode',
                            })
                        }
                        required
                    />
                </Grid>
            </Grid>
        </AxleAccordionContainer>
    );
}
