import { FormControl, FormHelperText, FormLabel, makeStyles, Select } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const getDisabledTextColor = props => {
    return props.fullAlphaDisabledText ? 'rgba(0, 0, 0, 1)' : 'rgba(0, 0, 0, 0.38)';
};

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& .MuiInputBase-input.Mui-disabled': {
            color: props => getDisabledTextColor(props),
        },
    },
    label: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '10px',
        color: 'black',
        marginBottom: theme.spacing(1.5),
        width: '100%',
        textAlign: 'left',
    },
    input: {
        width: '100%',
        borderRadius: '10px',
        textAlign: 'left',
        '& input, .MuiSelect-root': {
            padding: theme.spacing(1.5, 2),
        },
    },
}));

export default function AxleSelect({ label, customInput, children, error, customLabel, ...rest }) {
    const classes = useStyles(rest);
    const isError = error ? true : false;

    return (
        <FormControl className={classes.root} error={isError}>
            {customLabel ? customLabel : <FormLabel className={classes.label}>{label}</FormLabel>}
            {customInput ? (
                customInput
            ) : (
                <Select
                    variant='outlined'
                    {...rest}
                    className={clsx(classes.input, rest.className)}
                    error={isError}
                >
                    {children}
                </Select>
            )}
            {error && <FormHelperText variant='outlined'>{error}</FormHelperText>}
        </FormControl>
    );
}
