import { Divider, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { AxleButton } from './';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(2),
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        maxWidth: '376px',
        position: 'fixed',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(6),
            width: '80%',
            position: 'static',
        },
    },
    container: {
        width: '100%',
        marginTop: theme.spacing(4),
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '12px',
        alignItems: 'flex-start',
    },
    lineItemContainer: {
        width: '100%',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    lineItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    cta: {
        width: '260px',
        height: '48px',
        borderRadius: '24px!important',
        fontSize: '18px',
        padding: theme.spacing(1 / 2),
    },
    header: {
        marginBottom: theme.spacing(2),
        textAlign: 'left',
        fontSize: '24px',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            fontSize: '18px',
        },
    },
}));

export default function AxlePaymentBox(props) {
    const classes = useStyles();

    // Price may be a string like "Pre-paid" in which case we don't want to display $ sign
    const toDollars = price => {
        return isNaN(price) ? price.toString() : `$${price}`;
    };

    return (
        <div className={`${classes.root} ${props.className ? props.className : ''}`}>
            <div className={classes.lineItemContainer}>
                <Typography variant='h6' className={classes.header}>
                    <b>{props.title}</b>
                </Typography>
                {props.lineItems.map(lineItem => (
                    <div key={lineItem.id} className={classes.lineItem}>
                        <div className={classes.leftContainer}>
                            <Typography variant='subtitle1'>
                                <b>{lineItem.name}</b>
                            </Typography>
                            <Typography variant='caption'>
                                {`Quantity: ${lineItem.quantity}`}
                            </Typography>
                        </div>
                        <Typography variant='subtitle1' style={{ marginBottom: '12px' }}>
                            {toDollars(lineItem.price)}
                        </Typography>
                    </div>
                ))}
                <Divider />
                <div className={classes.lineItem}>
                    <Typography variant='subtitle1' style={{ marginTop: '12px' }}>
                        <b>Order Total</b>
                    </Typography>
                    <Typography variant='subtitle1' style={{ marginTop: '12px' }}>
                        {toDollars(props.orderTotal)}
                    </Typography>
                </div>
                <div
                    className={classes.container}
                    onClick={props.validateForm}
                    onMouseEnter={props.validateForm}
                >
                    <Hidden smDown>
                        <AxleButton
                            isLoading={props.isLoading}
                            disabled={props.disabled}
                            className={classes.cta}
                            onClick={props.onClick}
                            trackingEvent={{ name: 'Book' }}
                            tooltip={props.buttonTooltip}
                        >
                            {props.ctaTitle ? props.ctaTitle : 'Proceed to Payment'}
                        </AxleButton>
                    </Hidden>
                    <br />
                    <Typography variant='body1'>
                        Need help booking your visit? Contact us at&nbsp;
                        <a href={`mailto:${props.supportEmail}`}>{props.supportEmail}</a>
                    </Typography>
                </div>
            </div>
        </div>
    );
}

AxlePaymentBox.propTypes = {
    title: PropTypes.string.isRequired,
    lineItems: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    orderTotal: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    ctaTitle: PropTypes.string,
};
