import { Box, useTheme } from '@material-ui/core';
import React from 'react';
import logo from '../assets/logoCenterAligned.png';

const PreferNoWrap = ({ children }) => <span style={{ display: 'inline-block' }}>{children}</span>;

export default function ErrorFallback() {
    const theme = useTheme();
    const TECH_SUPPORT_EMAIL = 'techsupport@axlehealth.com';

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: theme.spacing(6),
                textAlign: 'center',
            }}
        >
            <img
                src={logo}
                style={{
                    height: 200,
                }}
                alt='Axle Health logo'
            />
            <Box
                sx={{
                    marginTop: theme.spacing(4),
                    maxWidth: '800px',
                    fontSize: '35px',
                    lineHeight: 1.25,
                }}
            >
                <p>
                    <PreferNoWrap>Unexpected error;</PreferNoWrap>{' '}
                    <PreferNoWrap>try refreshing the page.</PreferNoWrap>
                </p>
                <p>
                    If the problem persists, then please contact us at{' '}
                    <a href={`mailto:${TECH_SUPPORT_EMAIL}`}>{TECH_SUPPORT_EMAIL}</a>.
                </p>
            </Box>
        </Box>
    );
}
