import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    makeStyles,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    label: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '10px',
        color: 'black',
        marginBottom: theme.spacing(1.5),
        width: '100%',
        textAlign: 'left',
    },
    formLabel: {
        marginRight: 0,
        width: '100%',
    },
}));

export default function AxleMultiselect({
    options,
    error,
    handleQuestionResponse,
    questionId,
    label,
    customLabel,
}) {
    const classes = useStyles();
    const isError = error ? true : false;
    const [checked, setChecked] = useState(new Set());

    useEffect(() => {
        handleQuestionResponse({
            value: Array.from(checked),
            questionId: questionId,
        });
    }, [checked]);

    return (
        <FormControl className={classes.root} error={isError}>
            {customLabel ? (
                customLabel
            ) : (
                <InputLabel className={classes.label} error={isError}>
                    {label}
                </InputLabel>
            )}
            {options.map((option, idx) => {
                const isChecked = checked.has(option);
                return (
                    <FormControlLabel
                        key={idx}
                        className={classes.formLabel}
                        control={
                            <Checkbox
                                color='primary'
                                value={option}
                                checked={isChecked}
                                onChange={e => {
                                    if (e.target.checked) {
                                        const addChecked = new Set(checked);
                                        addChecked.add(e.target.value);
                                        setChecked(addChecked);
                                    } else {
                                        const removeChecked = new Set(checked);
                                        removeChecked.delete(e.target.value);
                                        setChecked(removeChecked);
                                    }
                                }}
                            />
                        }
                        label={option}
                    />
                );
            })}
            {error && <FormHelperText variant='outlined'>{error}</FormHelperText>}
        </FormControl>
    );
}
