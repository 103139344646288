const root = (state = [], action) => {
    switch (action.type) {
        case 'SET_ZIPCODE':
            return {
                ...state,
                zipCode: action.zipCode,
            };
        case 'SET_CITY':
            return {
                ...state,
                city: action.city,
            };
        case 'SET_USSTATE':
            return {
                ...state,
                usState: action.usState,
            };
        case 'SET_PARTNER_ID':
            return {
                ...state,
                partnerId: action.partnerId,
            };
        case 'SET_PARTNER_META':
            return {
                ...state,
                partnerMeta: action.partnerMeta,
            };
        case 'SET_PRESET_SERVICES':
            return {
                ...state,
                presetServices: action.presetServices,
            };
        case 'SET_PROVIDER_ID':
            return {
                ...state,
                providerId: action.providerId,
            };
        case 'SET_VISIT_REQUEST_ID':
            return {
                ...state,
                visitRequestId: action.visitRequestId,
            };
        case 'SET_TIER':
            return {
                ...state,
                tier: action.tier,
            };
        default:
            return state;
    }
};

export default root;
