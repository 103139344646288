import { Backdrop, CircularProgress, Typography, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { Component } from 'react';
import sadFace from '../assets/sad-face.png';
import happyFace from '../assets/happy-face.png';
import { withRouter } from 'react-router-dom';
import { addNpstoVisit, fetchVisitReviewMeta } from '../api';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    reviewContainer: {
        width: '80%',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
    },
    npsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing(2),
    },
    npsButtonsContainer: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
    },
    npsButton: {
        width: 50,
        height: 50,
        marginRight: '4px',
        borderRadius: 25,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        backgroundColor: '#CDCDCD',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    likelihoodContainer: {
        display: 'flex',
        flexContainer: 'row',
        width: '100%',
        marginTop: theme.spacing(1),
    },
    likelihoodLabel: {
        color: 'black',
        fontWeight: 600,
    },
    check: {
        backgroundColor: green[500],
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
});

class VisitReview extends Component {
    state = {
        visitId: '',
        isReviewed: false,
        presentBackdrop: true,
        showAnimation: false,
        npsCompleted: false,
    };

    async componentDidMount() {
        const { result } = await fetchVisitReviewMeta(this.props.match.params.visitId);
        if (result) {
            this.setState({
                visitId: this.props.match.params.visitId,
                isReviewed: result.is_reviewed,
                npsCompleted: result.nps_completed,
                presentBackdrop: false,
            });
        }
    }

    handleNpsButtonClick = async nps => {
        this.setState({ presentBackdrop: true });
        const { visitId } = this.state;
        const { error } = await addNpstoVisit({ visitId, nps });
        this.setState({ isSubmitting: false, presentBackdrop: false });
        if (!error) {
            this.setState({ isReviewed: true, npsCompleted: true });
        }
    };

    renderNpsScale = (start, end) => {
        const npsButtons = [];
        for (let i = start; i <= end; i++) {
            let backgroundColor;
            if (i < 7) {
                backgroundColor = '#fd5c63';
            } else if (i == 7 || i == 8) {
                backgroundColor = '#F0E68C';
            } else if (i > 8) {
                backgroundColor = '#32de84';
            }
            npsButtons.push(
                <div
                    className={this.props.classes.npsButton}
                    style={{ backgroundColor }}
                    onClick={() => this.handleNpsButtonClick(i)}
                >
                    {i}
                </div>
            );
        }

        return npsButtons;
    };

    renderContent = () => {
        const { classes } = this.props;
        const { isReviewed, npsCompleted } = this.state;
        return isReviewed && npsCompleted ? (
            <div>
                <CheckCircleIcon style={{ fontSize: 60, fill: 'green' }} />
                <Typography variant='h5'>Thank you for review!</Typography>
            </div>
        ) : (
            <div className={classes.reviewContainer}>
                <Typography gutterBottom variant='h6'>
                    On a scale of 0-10, how likely are you to recommend this service to a friend or
                    family member?
                </Typography>
                <div className={classes.likelihoodContainer} style={{ justifyContent: 'start' }}>
                    <div>
                        <img src={sadFace} style={{ height: '24px' }} />
                    </div>
                </div>
                <div className={classes.npsContainer}>
                    <div className={classes.npsButtonsContainer}>{this.renderNpsScale(0, 5)}</div>
                    <div className={classes.npsButtonsContainer}>{this.renderNpsScale(6, 10)}</div>
                </div>
                <div
                    className={classes.likelihoodContainer}
                    style={{
                        justifyContent: 'end',
                    }}
                >
                    <div>
                        <img src={happyFace} style={{ height: '24px' }} />
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { classes } = this.props;
        const { presentBackdrop } = this.state;
        return (
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={presentBackdrop}>
                    <CircularProgress color='inherit' />
                </Backdrop>
                {!presentBackdrop ? this.renderContent() : null}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(VisitReview));
