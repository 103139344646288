import { Box, Hidden, Snackbar, Typography, withStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchVisitDetails, getAvailability, rescheduleVisit } from '../../api';
import { AxleButton, AxleScheduleVisitBox } from '../../components';
import ScheduleContainerScheduling from './ScheduleContainerScheduling';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    leftContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 7,
        padding: theme.spacing(2), // Default the whole page to have some padding
        paddingTop: theme.spacing(5), // But override the top padding to be a bit larger.
        borderRight: '1px dashed lightgray',
        paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            borderRight: '0',
            borderBottom: '1px dashed lightgray',
        },
    },
    rightContainer: {
        display: 'flex',
        flex: 3,
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            alignItems: 'center',
            flex: 0,
        },
    },
    paymentBox: {
        marginRight: theme.spacing(4),
        marginTop: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2),
        },
    },
    payCta: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            width: '100%',
            position: 'fixed',
            height: '48px',
            bottom: 0,
            left: 0,
            zIndex: 9998,
        },
    },
    snackbar: {
        zIndex: 9999,
    },
});

class Scheduling extends React.Component {
    state = {
        patients: [
            {
                // Don't change id: 0; api relies on that to delineate between parent & child visit
                id: 0,
                // services selected by patient
                services: [],
            },
        ],
        address: {
            address1: '',
            address2: '',
            city: this.props.city || '',
            state: this.props.usState || '',
            zipCode: this.props.zipCode || '',
        },
        // all available services
        services: [],
        availability: {},
        visitTime: new Set(),
        isLoadingTimesgrid: false,
        isLoading: false,
        snackbar: { show: false, message: '', severity: 'error' },
        areServicesPreset: false,
        formErrors: {},
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        // If the user is coming from a partner site where the services have been chosen already
        // then we just want to fetch those from the api
        const { result, error } = await fetchVisitDetails(this.props.match.params.visitId);
        if (result) {
            this.setState({
                partnerId: result.partner_id,
                services: result.services,
                address: {
                    zipCode: result.zip_code,
                    address1: result.address,
                    city: true,
                    state: true,
                },
            });
            this.handleShowTimesgrid();
        }
        if (error) {
            this.setState({ formErrors: error });
        }
    }

    handleDatetimeSelected = newTimeSelection => {
        let currentlySelectedTimes = this.state.visitTime;
        currentlySelectedTimes = new Set();
        currentlySelectedTimes.add(newTimeSelection);
        this.setState({ visitTime: currentlySelectedTimes });
    };

    handleShowTimesgrid = async () => {
        const zipCode = this.state.address.zipCode;

        if (zipCode && zipCode.length > 1) {
            this.setState({ isLoadingTimesgrid: true });

            let result, helpText, error;
            ({ result, helpText, error } = await getAvailability({
                services: this.state.services,
                zipCode,
                partnerId: this.state.partnerId,
                patients: this.state.patients,
                visitId: this.props.match.params.visitId,
            }));

            if (result) {
                this.setState({
                    availability: result,
                    helpText: helpText,
                    isLoadingTimesgrid: false,
                });
            } else {
                this.setState({
                    isLoadingTimesgrid: false,
                    snackbar: { show: true, message: error, severity: 'error' },
                });
            }
        } else {
            this.setState({
                availability: {},
            });
        }
    };

    handleScheduleClick = async () => {
        this.setState({ isLoading: true });
        const { visitTime } = this.state;
        const visitId = this.props.match.params.visitId;
        const visitDatetime = visitTime.values().next().value;
        const { result, error } = await rescheduleVisit({
            visitId,
            visitDatetime,
        });

        if (result !== undefined) {
            this.props.history.push(`/confirmation/${visitId}`);
        } else {
            console.error(error);
            this.setState({
                isLoading: false,
                snackbar: { show: true, message: error, severity: 'error' },
            });
        }
    };

    canProceed = () => {
        const { visitTime } = this.state;
        const hasVisitTime = visitTime.size >= 1;
        return hasVisitTime && Object.keys(this.state.formErrors).length === 0;
    };

    handleCloseSnackbar = () => {
        this.setState({
            snackbar: { show: false, message: '', severity: 'error' },
        });
    };

    validateForm = () => {
        return;
    };

    getContainer = () => {
        const { patients, address, isLoadingTimesgrid, availability, visitTime } = this.state;

        const container = {
            renderFunc: () => (
                <ScheduleContainerScheduling
                    address={address}
                    patient={patients[0]}
                    isLoadingTimesgrid={isLoadingTimesgrid}
                    isServiceSelected={true}
                    visitTime={visitTime}
                    onDatetimeSelected={this.handleDatetimeSelected}
                    availability={availability}
                    tier={1}
                    helpText={this.state.helpText}
                />
            ),
        };

        return container;
    };

    render() {
        const { classes } = this.props;

        const container = this.getContainer();
        const showBookVisitBtn = this.state.areServicesPreset;

        return (
            <div className={classes.root}>
                <div className={classes.leftContainer}>{container.renderFunc()}</div>
                {/* Shown on desktop */}
                <div className={classes.rightContainer}>
                    <AxleScheduleVisitBox
                        className={classes.paymentBox}
                        ctaTitle='Schedule Visit'
                        title='Visit Summary'
                        supportEmail={this.props.partnerMeta.support_email}
                        onClick={this.handleScheduleClick}
                        disabled={!this.canProceed()}
                        isLoading={this.state.isLoading}
                        buttonTooltip={
                            showBookVisitBtn
                                ? 'All fields must be completed before scheduling your visit'
                                : ''
                        }
                        validateForm={this.validateForm}
                    />
                </div>
                {/* Show on mobile */}
                <Hidden mdUp>
                    <Box
                        onClick={this.validateForm}
                        onMouseEnter={this.validateForm}
                        className={classes.payCta}
                    >
                        <AxleButton
                            isLoading={this.state.isLoading}
                            className={classes.payCta}
                            onClick={this.handleScheduleClick}
                            trackingEvent={{ name: 'Scheduling' }}
                            tooltip={
                                showBookVisitBtn
                                    ? 'All fields must be completed before scheduling your visit'
                                    : ''
                            }
                            disabled={!this.canProceed()}
                        >
                            <Typography variant='button'>Schedule Visit</Typography>
                        </AxleButton>
                    </Box>
                </Hidden>
                <Snackbar
                    className={classes.snackbar}
                    open={this.state.snackbar.show}
                    autoHideDuration={5000}
                    onClose={this.handleCloseSnackbar}
                >
                    <MuiAlert
                        onClose={this.handleCloseSnackbar}
                        severity={this.state.snackbar.severity}
                    >
                        {this.state.snackbar.message}
                    </MuiAlert>
                </Snackbar>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    zipCode: state.zipCode,
    city: state.city,
    usState: state.usState,
    partnerId: state.partnerId,
    presetServices: state.presetServices,
    providerId: state.providerId,
    partnerMeta: state.partnerMeta,
    visitRequestId: state.visitRequestId,
});

export default withRouter(connect(mapStateToProps, null)(withStyles(styles)(Scheduling)));
