import { Box, MenuItem } from '@material-ui/core';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import React from 'react';
import AxleAccordionContainer from '../AxleAccordionContainer';
import AxleSelect from '../AxleSelect';

export default function ProviderContainer({
    providers,
    providerId,
    selectedProviderId,
    onChangeProvider,
}) {
    return providers ? (
        <AxleAccordionContainer
            title='Select provider'
            icon={<LocalHospitalIcon fontSize='small' color='primary' />}
        >
            <Box paddingTop='24px' width='100%' maxWidth='400px'>
                <AxleSelect
                    labelId='state-select-outlined-label'
                    id='state-select-outlined'
                    label='Provider'
                    disabled={providerId ? true : false}
                    value={providerId ? providerId : selectedProviderId}
                    onChange={onChangeProvider}
                >
                    {providers.map(prov => (
                        <MenuItem key={prov.id} value={prov.id}>
                            {`${prov.last_name}, ${prov.first_name} ${prov.title}`}
                        </MenuItem>
                    ))}
                </AxleSelect>
            </Box>
        </AxleAccordionContainer>
    ) : (
        <></>
    );
}
