import { Avatar, Backdrop, Box, CircularProgress, Typography, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Rating from '@material-ui/lab/Rating';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { fetchVisitReviewMeta, reviewVisit } from '../api';
import { AxleButton, AxleEditor } from '../components';

const styles = theme => ({
    root: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    reviewContainer: {
        width: '80%',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
    },
    check: {
        backgroundColor: green[500],
    },
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        margin: '0 auto',
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
    '@keyframes shake': {
        '10%, 90%': {
            transform: 'translate3d(-1px, 0, 0)',
        },
        '20%, 80%': {
            transform: 'translate3d(2px, 0, 0)',
        },
        '30%, 50%, 70%': {
            transform: 'translate3d(-4px, 0, 0)',
        },
        '40%, 60%': {
            transform: 'translate3d(4px, 0, 0)',
        },
    },
    shake: {
        animation: '$shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
        transform: 'translate3d(0, 0, 0)',
        backfaceVisibility: 'hidden',
        perspective: '1000px',
    },
});

const labels = {
    0: 'Select a rating by tapping the stars above',
    1: 'Rating: Poor (1 star)',
    2: 'Rating: Ok (2 stars)',
    3: 'Rating: Good (3 stars)',
    4: 'Rating: Great (4 stars)',
    5: 'Rating: Excellent (5 stars)',
};

class VisitReview extends Component {
    state = {
        visitId: '',
        rating: 0.0,
        comments: '',
        professionalName: '',
        imgSrc: '',
        isReviewed: false,
        isSubmitting: false,
        presentBackdrop: true,
        showAnimation: false,
    };

    async componentDidMount() {
        const { result } = await fetchVisitReviewMeta(this.props.match.params.visitId);
        if (result) {
            this.setState({
                visitId: this.props.match.params.visitId,
                isReviewed: result.is_reviewed,
                professionalName: result.professional_name,
                imgSrc: result.img_src,
                presentBackdrop: false,
            });
        }
    }

    handleSubmit = async () => {
        this.setState({ isSubmitting: true });
        const { rating, comments, visitId } = this.state;

        const { error } = await reviewVisit({ visitId, rating, comments });
        this.setState({ isSubmitting: false });
        if (!error) {
            this.props.history.push(`/nps/${visitId}`);
        }
    };

    handleAnimation = () => {
        if (!this.state.rating) {
            this.setState({ showAnimation: true }, () => {
                setTimeout(() => {
                    this.setState({ showAnimation: false });
                }, 1000);
            });
        }
    };

    renderContent = () => {
        const { classes } = this.props;
        const { professionalName, imgSrc, isReviewed, rating, isSubmitting } = this.state;
        return isReviewed ? (
            <div>
                <CheckCircleIcon style={{ fontSize: 60, fill: 'green' }} />
                <Typography variant='h5'>Thank you for review!</Typography>
            </div>
        ) : (
            <div className={classes.reviewContainer}>
                {imgSrc ? (
                    <Avatar className={classes.avatar} alt={professionalName} src={imgSrc} />
                ) : (
                    <Avatar className={classes.avatar}>{professionalName[0]}</Avatar>
                )}
                <Typography gutterBottom variant='h6'>
                    {professionalName}
                </Typography>
                <Box my={3}>
                    <Typography variant='subtitle2'>
                        Rate your visit from 1 to 5 stars below
                    </Typography>
                    <Box
                        my={1}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Rating
                            name='star-rating'
                            className={this.state.showAnimation ? classes.shake : ''}
                            value={rating}
                            onChange={(event, newValue) => {
                                console.log({ newValue });
                                this.setState({ rating: newValue || 0 });
                            }}
                            size='large'
                        />
                    </Box>
                    <Typography variant='subtitle2'>{labels[rating]}</Typography>
                </Box>
                <AxleEditor
                    placeholder={'Any comments about your visit?'}
                    onChange={notes => this.setState({ comments: notes })}
                />
                <div onClick={this.handleAnimation}>
                    <AxleButton
                        size='large'
                        isLoading={isSubmitting}
                        disabled={!rating}
                        onClick={this.handleSubmit}
                        on
                    >
                        Submit
                    </AxleButton>
                </div>
            </div>
        );
    };

    render() {
        const { classes } = this.props;
        const { presentBackdrop } = this.state;
        return (
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={presentBackdrop}>
                    <CircularProgress color='inherit' />
                </Backdrop>
                {!presentBackdrop ? this.renderContent() : null}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(VisitReview));
