import { Button, CircularProgress, SvgIcon, Tooltip, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactComponent as Check } from '../assets/check.svg';

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export default function AxleButton({ trackingEvent, tooltip, ...props }) {
    const classes = useStyles();

    const getButtonClass = buttonSuccess => {
        return `${props.className ? props.className : ''} ${
            buttonSuccess ? classes.buttonSuccess : ''
        }`;
    };

    const handleClick = e => {
        props.onClick(e);
    };

    const ButtonContent = ({ children }) => {
        return tooltip && props.disabled && !props.isLoading ? (
            <Tooltip title={<Typography variant='subtitle2'>{tooltip}</Typography>} placement='top'>
                {children}
            </Tooltip>
        ) : (
            <>{children}</>
        );
    };

    const shouldDisableButton = () => {
        return props.isLoading || props.disabled;
    };

    return (
        <ButtonContent>
            <div className={`${classes.wrapper} ${props.containerClass || ''}`}>
                <Button
                    style={{ borderRadius: props.rounded ? 25 : 0 }}
                    variant={props.variant || 'contained'}
                    color={props.color || 'primary'}
                    className={getButtonClass(props.isSuccessful)}
                    disabled={shouldDisableButton()}
                    onClick={handleClick}
                    size={props.size || 'medium'}
                    type={props.type || 'button'}
                    disableElevation={props.disableElevation || false}
                >
                    {props.isSuccessful ? <SvgIcon component={Check} /> : props.children}
                </Button>
                {props.isLoading && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
            </div>
        </ButtonContent>
    );
}

AxleButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    isSuccessful: PropTypes.bool,
    children: PropTypes.any,
    color: PropTypes.string,
    variant: PropTypes.string,
    size: PropTypes.string,
    rounded: PropTypes.bool,
};
