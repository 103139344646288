import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useDropzone } from 'react-dropzone';

const getColor = props => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
};

const useStyles = makeStyles(() => ({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: '2px',
        borderRadius: '2px',
        borderColor: `${props => getColor(props)}`,
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    },
}));

export default function AxleDropzone(props) {
    const classes = useStyles();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png, application/pdf',
        onDropAccepted: props.onUpload,
        multiple: false,
    });

    const files = acceptedFiles.map(file => (
        <li style={{ textAlign: 'left' }} key={file.path}>
            {file.path}
        </li>
    ));

    return (
        <section className='fs-exclude'>
            <div {...getRootProps({ className: classes.container })}>
                <input {...getInputProps()} />
                <p>{`Drag 'n' drop files here, or click to select files`}</p>
                <em>(Only *.jpeg and *.png, and *.pdf files will be accepted)</em>
            </div>
            <aside>
                {files.length ? (
                    <div>
                        <h4>{files}</h4>
                    </div>
                ) : null}
            </aside>
        </section>
    );
}

AxleDropzone.propTypes = {
    onUpload: PropTypes.func.isRequired,
};
