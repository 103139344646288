import { Box, Checkbox, FormControlLabel, makeStyles, Typography } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import React from 'react';
import AxleAccordionContainer from '../AxleAccordionContainer';

const useStyles = makeStyles(theme => ({
    bookingContainer: {
        minWidth: '600px',
        [theme.breakpoints.down('xs')]: {
            minWidth: 0,
        },
    },
    serviceRowContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        marginBottom: theme.spacing(2),
        '& .MuiFormControlLabel-root': {
            alignItems: 'flex-start',
        },
        '& .MuiCheckbox-root': {
            padding: theme.spacing(0, 1),
        },
    },
    serviceFormControlLabel: {
        height: '40px',
        textAlign: 'left',
    },
    serviceWithInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: '1rem',
    },
    serviceName: {
        minWidth: '200px',
        textAlign: 'left',
        fontSize: '1rem',
        fontWeight: 'bold',
    },
}));

export default function ServiceContainer({
    patients,
    services,
    areServicesPreset,
    isPaid,
    handleServiceSelected,
    sectionRef,
    whoRef,
}) {
    const classes = useStyles();

    const allPatientsComplete = patients => {
        for (const p of patients) {
            if (!isPatientComplete(p)) {
                return false;
            }
        }

        return true;
    };

    const constructPriceLabel = service => {
        if (areServicesPreset && isPaid) {
            return `(Pre-paid)`;
        } else {
            return `$${service.price}`;
        }
    };

    return (
        <AxleAccordionContainer
            title='What?'
            icon={<AddBoxIcon fontSize='small' color='primary' />}
            containerRef={sectionRef}
        >
            <Box component='div' width='100%'>
                {patients.length > 0 && allPatientsComplete(patients) ? (
                    patients.map(patient =>
                        isPatientComplete(patient) ? (
                            <div key={patient.id} className={classes.serviceRowContainer}>
                                <Typography variant='subtitle1'>
                                    {`Which services ${
                                        patient.id === 0 ? 'do you' : 'does ' + patient.firstName
                                    } need?`}
                                </Typography>
                                <table>
                                    {services.map(service => {
                                        const checked = patient.services.filter(
                                            s => s.id === service.id
                                        ).length;
                                        return service.isGlobal === false ? (
                                            <tr
                                                key={service.id}
                                                className={classes.serviceFormControlLabel}
                                            >
                                                <td>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={checked ? true : false}
                                                                disabled={areServicesPreset}
                                                                color='primary'
                                                                onChange={e =>
                                                                    handleServiceSelected({
                                                                        checked: e.target.checked,
                                                                        patient,
                                                                        service,
                                                                    })
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <div
                                                                className={
                                                                    classes.serviceWithInfoContainer
                                                                }
                                                            >
                                                                <Box display='flex'>
                                                                    <Typography
                                                                        variant='subtitle1'
                                                                        className={
                                                                            classes.serviceName
                                                                        }
                                                                    >
                                                                        {service.name}
                                                                    </Typography>
                                                                </Box>
                                                                <Typography
                                                                    style={{
                                                                        color: 'grey',
                                                                    }}
                                                                    variant='caption'
                                                                >
                                                                    {service.description}
                                                                </Typography>
                                                            </div>
                                                        }
                                                    />
                                                </td>
                                                <td>
                                                    <Typography variant='body1'>
                                                        {constructPriceLabel(service)}
                                                    </Typography>
                                                </td>
                                            </tr>
                                        ) : null;
                                    })}
                                </table>
                            </div>
                        ) : null
                    )
                ) : (
                    <a href='javascript:void(0)' onClick={() => whoRef.current.scrollIntoView()}>
                        <Typography
                            variant='body1'
                            style={{ marginTop: '16px', textAlign: 'left' }}
                        >
                            Ensure you complete all additional patient information
                        </Typography>
                    </a>
                )}
            </Box>
        </AxleAccordionContainer>
    );
}

function isPatientComplete(patient) {
    const { phone, firstName, lastName, dob, sex } = patient;
    if (patient.id === 0) {
        return phone && firstName && lastName && sex && dob && dob !== 'Invalid date';
    } else {
        return firstName && lastName && phone && sex && dob && dob !== 'Invalid date';
    }
}
