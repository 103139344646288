import { Box } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import React from 'react';
import AxleAccordionContainer from '../AxleAccordionContainer';
import AxleDropzone from '../AxleDropzone';

export default function ProviderContainer({ handleUpload }) {
    return (
        <AxleAccordionContainer
            title='Upload Documentation'
            icon={<PublishIcon fontSize='small' color='primary' />}
        >
            <Box paddingTop='24px'>
                <AxleDropzone onUpload={handleUpload} />
            </Box>
        </AxleAccordionContainer>
    );
}
