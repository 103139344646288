export const setZipcode = zipCode => ({
    type: 'SET_ZIPCODE',
    zipCode,
});

export const setCity = city => ({
    type: 'SET_CITY',
    city,
});

export const setUsState = usState => ({
    type: 'SET_USSTATE',
    usState,
});

export const setTier = tier => {
    return {
        type: 'SET_TIER',
        tier,
    };
};

export const setPartnerId = partnerId => ({
    type: 'SET_PARTNER_ID',
    partnerId,
});

export const setPartnerMeta = partnerMeta => ({
    type: 'SET_PARTNER_META',
    partnerMeta,
});

export const setPresetServices = presetServices => ({
    type: 'SET_PRESET_SERVICES',
    presetServices,
});

export const setProviderId = providerId => ({
    type: 'SET_PROVIDER_ID',
    providerId,
});

export const setVisitRequestId = visitRequestId => ({
    type: 'SET_VISIT_REQUEST_ID',
    visitRequestId,
});
