export const routesWithoutNav = ['/availability'];

export const allUSStates = [
    { abbrev: 'AK', verbose: 'Alaska' },
    { abbrev: 'AL', verbose: 'Alabama' },
    { abbrev: 'AR', verbose: 'Arkansas' },
    { abbrev: 'AZ', verbose: 'Arizona' },
    { abbrev: 'CA', verbose: 'California' },
    { abbrev: 'CO', verbose: 'Colorado' },
    { abbrev: 'CT', verbose: 'Connecticut' },
    { abbrev: 'DC', verbose: 'Washington DC' },
    { abbrev: 'DE', verbose: 'Delaware' },
    { abbrev: 'FL', verbose: 'Florida' },
    { abbrev: 'GA', verbose: 'Georgia' },
    { abbrev: 'HI', verbose: 'Hawaii' },
    { abbrev: 'IA', verbose: 'Iowa' },
    { abbrev: 'ID', verbose: 'Idaho' },
    { abbrev: 'IL', verbose: 'Illinois' },
    { abbrev: 'IN', verbose: 'Indiana' },
    { abbrev: 'KS', verbose: 'Kansas' },
    { abbrev: 'KY', verbose: 'Kentucky' },
    { abbrev: 'LA', verbose: 'Louisiana' },
    { abbrev: 'MA', verbose: 'Massachusetts' },
    { abbrev: 'MD', verbose: 'Maryland' },
    { abbrev: 'ME', verbose: 'Maine' },
    { abbrev: 'MI', verbose: 'Michigan' },
    { abbrev: 'MN', verbose: 'Minnesota' },
    { abbrev: 'MO', verbose: 'Missouri' },
    { abbrev: 'MS', verbose: 'Mississippi' },
    { abbrev: 'MT', verbose: 'Montana' },
    { abbrev: 'NC', verbose: 'North Carolina' },
    { abbrev: 'ND', verbose: 'North Dakota' },
    { abbrev: 'NE', verbose: 'Nebraska' },
    { abbrev: 'NH', verbose: 'New Hampshire' },
    { abbrev: 'NJ', verbose: 'New Jersey' },
    { abbrev: 'NM', verbose: 'New Mexico' },
    { abbrev: 'NV', verbose: 'Nevada' },
    { abbrev: 'NY', verbose: 'New York' },
    { abbrev: 'OH', verbose: 'Ohio' },
    { abbrev: 'OK', verbose: 'Oklahoma' },
    { abbrev: 'OR', verbose: 'Oregon' },
    { abbrev: 'PA', verbose: 'Pennsylvania' },
    { abbrev: 'RI', verbose: 'Rhode Island' },
    { abbrev: 'SC', verbose: 'South Carolina' },
    { abbrev: 'SD', verbose: 'South Dakota' },
    { abbrev: 'TN', verbose: 'Tennessee' },
    { abbrev: 'TX', verbose: 'Texas' },
    { abbrev: 'UT', verbose: 'Utah' },
    { abbrev: 'VA', verbose: 'Virginia' },
    { abbrev: 'VT', verbose: 'Vermont' },
    { abbrev: 'WA', verbose: 'Washington' },
    { abbrev: 'WI', verbose: 'Wisconsin' },
    { abbrev: 'WV', verbose: 'West Virginia' },
    { abbrev: 'WY', verbose: 'Wyoming' },
];

export const BaseColors = {
    textGray: '#C2C2C2',
    mainColor: '#54BBBD',
};

export const SupportPhoneNumber = 4244071648;

export const ValidateMessages = {
    required: 'Complete this field',
    email: 'Invalid email format',
    phone: 'Invalid phone number format',
};

export const cancellationOptions = [
    'I can no longer make the scheduled time work.',
    "I don't know what this visit is for.",
    "I didn't schedule this visit.",
    'Other',
];
