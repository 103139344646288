import DateFnsUtils from '@date-io/date-fns';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { BaseColors } from '../constants';
import { getDatetimeFromString } from '../utils';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        overflow: 'hidden',
        '& .MuiPickersBasePicker-pickerView': {
            position: 'relative',
            maxWidth: 'inherit',
            [theme.breakpoints.up('sm')]: {
                minWidth: 'auto',
                minHeight: '335px',
                marginTop: theme.spacing(-2),
            },
            '& .MuiPickersCalendarHeader-switchHeader': {
                margin: theme.spacing(0, 0, 2, 0),
                '& button': {
                    position: 'absolute',
                    '&:first-child': {
                        [theme.breakpoints.up('sm')]: {
                            left: theme.spacing(3),
                        },
                        [theme.breakpoints.down('sm')]: {
                            left: theme.spacing(-3 / 2),
                        },
                        top: '50%',
                        transform: 'translate(0, -50%)',
                    },
                    '&:nth-child(3)': {
                        [theme.breakpoints.up('sm')]: {
                            right: theme.spacing(3),
                        },
                        [theme.breakpoints.down('sm')]: {
                            right: theme.spacing(-3 / 2),
                        },
                        top: '50%',
                        transform: 'translate(0, -50%)',
                    },
                },
            },
            '& .MuiPickersCalendarHeader-transitionContainer p': {
                fontSize: '1.25rem',
            },
            '& .MuiPickersCalendarHeader-dayLabel': {
                [theme.breakpoints.up('sm')]: {
                    color: 'rgba(0, 0, 0, 0.25)',
                    margin: theme.spacing(1 / 4, 2),
                    fontSize: '1.25rem',
                },
            },
            '& .MuiPickersDay-day': {
                [theme.breakpoints.up('sm')]: {
                    margin: theme.spacing(0, 7 / 4),
                    width: '40px',
                    height: '40px',
                    backgroundColor: 'rgb(84, 187, 189, 0.15)',
                    '& .MuiIconButton-label p': {
                        fontSize: '1.25rem',
                        color: BaseColors.mainColor,
                    },
                },
            },
            '& .MuiPickersDay-dayDisabled': {
                backgroundColor: 'white !important',
                '& .MuiIconButton-label p': {
                    color: 'grey !important',
                },
            },
            '& .MuiPickersDay-daySelected': {
                backgroundColor: `${BaseColors.mainColor} !important`,
                '& .MuiIconButton-label p': {
                    color: 'white !important',
                },
            },
        },

        '& .MuiPickersDatePickerRoot-toolbar': {
            height: '50px',
            minHeight: '50px',
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'flex-end',
            '& button': {
                '&:first-child': {
                    display: 'none',
                },
            },
            '& .MuiPickersToolbarText-toolbarBtnSelected': {
                color: 'black',
                fontWeight: 'bold',
                fontSize: '1.5rem',
            },
        },
    },
    timeButton: {
        width: 226,
        height: 50,
        borderRadius: '30px',
        fontSize: '0.9rem',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
        '&:hover': {
            borderColor: BaseColors.mainColor,
            color: BaseColors.mainColor,
        },
    },
    timeButtonSelected: {
        borderColor: BaseColors.mainColor,
        color: BaseColors.mainColor,
    },
    timeItem: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center!important',
        },
    },
    timeContainer: {
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            height: '200px',
            overflow: 'auto',
        },
    },
}));

export default function AxleTimesgrid(props) {
    const classes = useStyles();
    const [selectedDate, setSeletedDate] = useState(moment());
    const { noAvailabilityAction } = props;

    useEffect(
        function setFirstAvailableDateAsSelected() {
            if (!props.availability) {
                return null;
            }

            const dateStr = moment(selectedDate).format('YYYY-MM-DD');
            const availability = props.availability[dateStr];
            if (!availability || !availability.length) {
                const availableDates = Object.keys(props.availability);
                for (let i = 0; i < availableDates.length; i++) {
                    const key = availableDates[i];
                    const data = props.availability[key];
                    const date = moment(key, 'YYYY-MM-DD');
                    if (date >= moment(selectedDate) && data && data.length) {
                        setSeletedDate(date);
                        break;
                    }
                }
            }
        },

        [props.availability]
    );

    const shouldDisableDate = date => {
        const formattedDate = moment(date).format('YYYY-MM-DD');
        return formattedDate in props.availability && props.availability[formattedDate].length > 0
            ? false
            : true;
    };

    const formatDate = datetime => moment(getDatetimeFromString(datetime)).format('LT');

    const renderTimes = () => {
        const dateStr = moment(selectedDate).format('YYYY-MM-DD');
        const availability = props.availability[dateStr];
        return availability && availability.length && !props.noAvailabilityText ? (
            <Grid
                container
                spacing={2}
                className={`${classes.timeContainer} ${props.timeContainerClass || ''}`}
            >
                {availability.map((time, i) => {
                    return (
                        <Grid
                            key={time}
                            item
                            md={6}
                            sm={6}
                            xs={12}
                            className={classes.timeItem}
                            style={{ textAlign: i % 2 == 0 ? 'right' : 'left' }}
                        >
                            <Button
                                key={time}
                                className={clsx(classes.timeButton, {
                                    [classes.timeButtonSelected]:
                                        props.visitTime && props.visitTime.has(time),
                                })}
                                variant='outlined'
                                onClick={() => props.onDatetimeSelected(time)}
                                disabled={props.isLoading || props.disabled}
                            >
                                {!props.isAvailabilityTimeWindow
                                    ? formatDate(time)
                                    : `${formatDate(time['start_time'])} - ${formatDate(
                                          time['end_time']
                                      )}`}
                            </Button>
                        </Grid>
                    );
                })}
            </Grid>
        ) : (
            <a href='javascript:void(0)' onClick={noAvailabilityAction}>
                <Typography variant='body1' className={classes.timeContainer}>
                    {props.noAvailabilityText || 'No availability'}
                </Typography>
            </a>
        );
    };

    return (
        <div className={classes.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                    disablePast
                    shouldDisableDate={shouldDisableDate}
                    variant='static'
                    defaultValue={selectedDate}
                    value={selectedDate}
                    onChange={date => setSeletedDate(moment(date))}
                    fullWidth
                    orientation='portrait'
                    disabled={props.isLoading || props.disabled}
                />
            </MuiPickersUtilsProvider>
            {props.helpText && (
                <>
                    <Typography>{props.helpText}</Typography>
                    <br />
                    <br />
                </>
            )}
            {props.isLoading ? <CircularProgress /> : renderTimes()}
        </div>
    );
}
