import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    makeStyles,
    Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        minWidth: '600px',
        padding: theme.spacing(5),
        maxWidth: '100%',
        border: '1px solid #E8E6E1',
        borderRadius: '8px',
        boxShadow: 'none',
        [theme.breakpoints.down('xs')]: {
            minWidth: 0,
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(5, 0),
        },
        '& .MuiAccordionDetails-root': {
            padding: theme.spacing(0, 2, 2),
        },
        '& .MuiAccordionSummary-content': {
            margin: 0,
            alignItems: 'center',
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            minHeight: theme.spacing(6),
        },
        '&:before': {
            height: 0,
        },
    },
    heading: {
        fontSize: '36px',
        fontWeight: 'bold',
        marginLeft: theme.spacing(2),
        textAlign: 'left',
        [theme.breakpoints.down('sm')]: {
            fontSize: '25px',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: theme.spacing(0, 4),
        width: '100%',
        '& .MuiGrid-item': {
            width: '100%',
        },
    },
}));

export default function AxleAccordionContainer({
    className,
    title,
    subTitle,
    icon,
    children,
    containerRef,
}) {
    const classes = useStyles();

    return (
        <Accordion
            className={clsx(classes.root, className)}
            variant='elevation'
            defaultExpanded={true}
            ref={containerRef}
        >
            <AccordionSummary>
                {icon}
                <Typography className={classes.heading}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box className={classes.content}>
                    {subTitle && (
                        <Box paddingBottom={3} textAlign='left' className='subTitle'>
                            <Typography>{subTitle}</Typography>
                        </Box>
                    )}
                    {children}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
}
