import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ContentState, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2),
        maxWidth: '100%',
    },
    editorStyle: {
        width: '300px',
        [theme.breakpoints.down('sm')]: {
            width: '250px',
        },
    },
}));

export default function AxleEditor(props) {
    const classes = useStyles();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [plainValue, setPlainValue] = useState('');

    const handleEditorChange = editorState => {
        setEditorState(editorState);
        props.onChange(stateToHTML(editorState.getCurrentContent()));
    };

    const handlePlainChange = e => {
        setPlainValue(e.target.value);
        props.onChange(e.target.value);
    };

    useEffect(() => {
        // Only do rich text because otherwise things will break
        if (props.isRichText) {
            // This will be called when visit notes are passed in from visit request ids.
            const currentContent = stateToHTML(editorState.getCurrentContent());
            const emptyContent = stateToHTML(EditorState.createEmpty().getCurrentContent());
            // Only set default if there is no content yet.
            if (currentContent === emptyContent && props.value != '<p><br></p>') {
                setEditorState(
                    EditorState.createWithContent(ContentState.createFromText(props.value))
                );
            }
        }
    }, [props.value]);

    return (
        <div className={`${classes.root} fs-exclude`}>
            {props.isRichText ? (
                <Editor
                    // Options: https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
                    toolbar={{
                        options: ['inline', 'list'],
                        inline: { options: ['bold', 'italic', 'underline'] },
                        list: { options: ['unordered', 'ordered'] },
                    }}
                    editorState={editorState}
                    toolbarClassName='toolbarClassName'
                    wrapperClassName='wrapperClassName'
                    editorClassName={classes.editorStyle}
                    onEditorStateChange={handleEditorChange}
                    placeholder={props.placeholder || ''}
                />
            ) : (
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    placeholder={props.placeholder || ''}
                    variant='outlined'
                    value={plainValue}
                    onChange={handlePlainChange}
                />
            )}
        </div>
    );
}

AxleEditor.propTypes = {
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    isRichText: PropTypes.bool,
};
