import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        maxWidth: '100%',
        border: '1px solid #E8E6E1',
        borderRadius: '8px',
        [theme.breakpoints.up('sm')]: {
            margin: '0 auto',
            marginTop: theme.spacing(6),
        },
    },
    greyBox: {
        height: '40px',
        width: '100%',
        padding: theme.spacing(2),
        backgroundColor: '#E8E6E1',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

export default function AxleBookingCardContainer(props) {
    const classes = useStyles();

    return (
        <div className={`${classes.root} ${props.className ? props.className : ''}`}>
            <div className={classes.greyBox}>
                <Typography variant='h6'>
                    <b>{props.title}</b>
                </Typography>
            </div>
            {props.children}
        </div>
    );
}

AxleBookingCardContainer.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
};
