import { Backdrop, CircularProgress, Typography, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { fetchQhpQuestions, saveQhpAnswers } from '../api';
import { AxleButton } from '../components';
import { QuestionContainer } from '../components/Book';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    notesContainer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
    },
    check: {
        backgroundColor: green[500],
    },
    avatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        margin: '0 auto',
    },
    backdrop: {
        zIndex: 9999,
        color: '#fff',
    },
});

// The standalone intake form is NOT for the patient, it's for the health professional
// This is used in a webview inside the QHP apps, but put this component here for now
// for the sake of simplicity. Eventually this should be native to mobile or housed as well
class StandaloneIntakeForm extends Component {
    state = {
        questions: [],
        answers: [],
        isComplete: false,
        visitId: '',
    };

    async componentDidMount() {
        const questionsResponse = await fetchQhpQuestions(this.props.match.params.visitId);
        if (questionsResponse && questionsResponse.result) {
            this.setState({
                questions: questionsResponse.result,
                visitId: this.props.match.params.visitId,
            });
        }
    }

    handleQuestionResponse = ({ questionId, value }) => {
        const { answers } = this.state;
        const updatedAnswers = [];
        updatedAnswers.push({ questionId, value });
        for (const a of answers) {
            // If the new answers is updating an existing one then don't include the existing one
            if (a.questionId !== questionId) {
                updatedAnswers.push(a);
            }
        }
        this.setState({ answers: updatedAnswers });
    };

    handleSubmit = async () => {
        this.setState({ isSubmitting: true });
        const { answers, visitId } = this.state;

        const { error } = await saveQhpAnswers({ visitId, answers });
        this.setState({ isSubmitting: false });
        if (!error) {
            this.setState({ isComplete: true });
        }
    };

    renderContent = () => {
        const { classes } = this.props;
        const { isComplete, isSubmitting } = this.state;
        return isComplete ? (
            <div>
                <CheckCircleIcon style={{ fontSize: 60, fill: 'green' }} />
                <Typography variant='h5'>Submitted!</Typography>
            </div>
        ) : (
            <div className={classes.notesContainer}>
                <QuestionContainer
                    questions={this.state.questions}
                    handleQuestionResponse={this.handleQuestionResponse}
                    errors={{}}
                />
                <AxleButton size='large' isLoading={isSubmitting} onClick={this.handleSubmit}>
                    Submit
                </AxleButton>
            </div>
        );
    };

    render() {
        const { classes } = this.props;
        const { presentBackdrop } = this.state;
        return (
            <div className={classes.root}>
                <Backdrop className={classes.backdrop} open={presentBackdrop}>
                    <CircularProgress color='inherit' />
                </Backdrop>
                {!presentBackdrop ? this.renderContent() : null}
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(StandaloneIntakeForm));
